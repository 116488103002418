import { GameBase } from "./game-base";
import { Vector2 } from "./utils";
import { audio } from "./assets";

import { SpaceShip } from "./entities/space-ship";
import { WashingMachine } from "./entities/washing-machine";
import { Bullet } from "./entities/bullet";
import { Stick } from "./stick";

export class Game extends GameBase {
  private ship: SpaceShip;
  private enemies: WashingMachine[];
  private bullets: Bullet[];
  private _lastEnemyTime: number;
  private _lastShot: number;
  private _enemySpawnTime: number;
  private _enemyLimit: number;
  private _died: boolean;
  private _points: number;

  public onDeath: () => void;
  public stick?: Stick;

  constructor(canvas: HTMLCanvasElement) {
    super(canvas);

    this.ship = new SpaceShip();
    this.enemies = [];
    this.bullets = [];
    this._lastShot = Infinity;
    this._lastEnemyTime = 0;
    this._enemySpawnTime = 2000;
    this._enemyLimit = 10;
    this.onDeath = undefined;
    this._died = false;
    this.stick = undefined;
    this._points = 0;
  }

  protected loop(elapsedTime: number): void {
    //Spawn enemy
    if (this.stick !== undefined) {
      const dir = this.stick.direction;
      if (this.stick.direction.length > 0) {
        this.ship.targetDirection = dir;
      }
    }

    this._spawnEnemy(elapsedTime);
    this.ship.update(elapsedTime);
    this._lastShot += elapsedTime;

    this.enemies.forEach(x => x.update(elapsedTime));
    this.bullets.forEach(x => x.update(elapsedTime));

    this.enemies.forEach(enemy => {
      if (enemy.position.distance(Vector2.NULL) < 30) {
        enemy.destroy();
        this.die();
      }

      this.bullets.forEach(bullet => {
        if (bullet.position.distance(enemy.position) < 30) {
          enemy.destroy();
          if (!bullet.destroyed) {
            this._points++;
            audio("bummSound").play();
          }
          bullet.destroyed = true;
        }
      });
    });

    this.enemies = this.enemies.filter(x => !x.destroyed);
    this.bullets = this.bullets.filter(x => x.position.distance(Vector2.NULL) < 3000 && !x.destroyed);

    this.bullets.forEach(x => x.render(this.ctx));
    this.ship.render(this.ctx);
    this.enemies.forEach(x => x.render(this.ctx));
    this.drawPoint();
  }

  private drawPoint() {
    const points = this._points.toString();
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.font = "2.5em Pixeled";
    this.ctx.fillStyle = "white";
    const textMetric = this.ctx.measureText(points);
    this.ctx.fillText(points, this.canvas.width / 2 - textMetric.width / 2, 75);
    this.ctx.restore();
  }

  private _spawnEnemy(elapsedTime: number) {
    this._lastEnemyTime += elapsedTime;
    if (this._lastEnemyTime > this._enemySpawnTime) {
      this._lastEnemyTime -= this._enemySpawnTime;
      if (this._enemyLimit >= this.enemies.length) {
        const pos = Math.random() * Math.PI * 2;
        this.enemies.push(new WashingMachine(new Vector2(Math.sin(pos) * 500, Math.cos(pos) * 500)));
      }
    }
  }

  public play(): void {
    super.play();

    const music = audio("music");
    if (this._died) {
      this._died = false;
      this.enemies = [];
      this.bullets = [];
      this._points = 0;
      music.currentTime = 0;
    }
    music.play();
  }

  public pause(): void {
    super.pause();
    audio("music").pause();
  }

  private die() {
    if (!this._died) {
      this._died = true;
      this.pause();
      const dieSound = audio("dieSound");
      dieSound.play();
      dieSound.onended = this.onDeath;
    }
  }

  public handleMouseMove(ev: MouseEvent): void {
    const x = ev.clientX - this.canvas.clientWidth / 2;
    const y = ev.clientY - this.canvas.clientHeight / 2;
    this.ship.targetDirection = new Vector2(x, y);
  }

  public shoot(): void {
    if (this._lastShot > this.ship.coolDown) {
      this.bullets.push(new Bullet(Vector2.NULL, this.ship.direction));
      this._lastShot = 0;
      audio("shotSound").play();
    }
  }

  public turn(value: number): void {
    this.ship.angle += (value / 180) * Math.PI;
  }
}
