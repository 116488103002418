import { Vector2 } from "../utils";
import { Explosion } from "../effects/explosion";
import { Entity } from "./entity";
import { Head } from "../effects/head";
import { image } from "../assets";

type WashingMachineStates = "IDLE" | "EXPLODING";

export class WashingMachine extends Entity {
  public position: Vector2;
  public speed: number;
  public destroyed: boolean;
  public direction: Vector2;
  public state: WashingMachineStates;
  public head: Head;

  public bumm: Explosion;
  constructor(position: Vector2) {
    super();
    this.position = position;
    this.speed = 100;
    this.destroyed = false;
    this.state = "IDLE";
    this.bumm = new Explosion();
    this.head = new Head(image("zsofi"));
    this.head.isRotating = true;
  }

  update(deltaTime: number): void {
    this.head.update(deltaTime);

    if (this.state === "IDLE") {
      if (this.position.distance(Vector2.NULL) > 1) {
        this.direction = this.position.normalize().scale(-1 * this.speed * (deltaTime / 1000));
        this.position = this.position.add(this.direction);
      } else {
        this.position = Vector2.NULL;
      }
    } else if (this.state === "EXPLODING") {
      this.bumm.update(deltaTime);
      if (this.bumm.isEnded()) {
        this.destroyed = true;
      }
    }
  }

  render(ctx: CanvasRenderingContext2D): void {
    const texture = image("washingMachine");
    ctx.save();
    const scale = 80 / Math.max(texture.width, texture.height);
    const w = texture.width * scale;
    const h = texture.height * scale;
    ctx.translate(this.position.x, this.position.y);
    ctx.rotate(this.direction.angle + Math.PI / 2);
    this.head.draw(ctx, 0, -5, 35, 35);

    ctx.drawImage(texture, -w / 2, -h / 2, w, h);

    if (this.state === "EXPLODING" && this.bumm.ready) {
      this.bumm.draw(ctx, 0, 12, h, w);
    }
    ctx.restore();
  }

  public destroy(): void {
    this.state = "EXPLODING";
  }
}
