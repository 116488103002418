export class Head {
  private _angle: number;
  private _rotationSpeed: number;
  private _img: CanvasImageSource;

  public isRotating: boolean;

  constructor(headImage: CanvasImageSource) {
    this._img = headImage;
    this._angle = 0;
    this.isRotating = false;
    this._rotationSpeed = Math.PI / 1000;
  }

  update(deltaTime: number): void {
    if (this.isRotating) {
      this._angle += this._rotationSpeed * deltaTime;
    }
  }

  draw(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number): void {
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(this._angle);
    ctx.drawImage(this._img, -w / 2, -h / 2, w, h);
    ctx.restore();
  }
}
