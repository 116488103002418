import { image } from "../assets";
import { Head } from "../effects/head";
import { Vector2 } from "../utils";
import { Entity } from "./entity";

export class Bullet extends Entity {
  private _direction: Vector2;
  public position: Vector2;
  public destroyed: boolean;
  public speed: number;
  public head: Head;

  constructor(position: Vector2, direction: Vector2) {
    super();
    this.position = position;
    this.speed = 300;
    this.destroyed = false;
    this._direction = direction.normalize();
    this.head = new Head(image("dini"));
  }

  update(deltaTime: number): void {
    this.position = this.position.add(this._direction.scale(this.speed * (deltaTime / 1000)));
  }

  render(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.translate(this.position.x, this.position.y);
    ctx.rotate(this._direction.rotate90().angle);
    ctx.drawImage(image("ghci"), -9, -9, 18, 80);
    this.head.draw(ctx, 0, 0, 32, 32);
    ctx.restore();
  }
}
