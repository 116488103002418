import { Game } from "./game";
import { Assets } from "./assets";
import { Stick } from "./stick";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(registration => {
        console.log("SW registered: ", registration);
      })
      .catch(registrationError => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

const gameContainer = document.querySelector<HTMLDivElement>("#game");
const canvas = document.querySelector<HTMLCanvasElement>("#gameCanvas");
const playButton = document.querySelector("#playButton");
const logoContainer = document.querySelector<HTMLDivElement>("#logo");
const installButton = document.querySelector<HTMLButtonElement>("#installButton");
const stick = document.querySelector<HTMLCanvasElement>("#stick");
const shotButton = document.querySelector<HTMLCanvasElement>("#shotButton");
const ui = document.querySelector<HTMLDivElement>("#ui");

gameContainer.addEventListener("mousemove", ev => {
  const x = (-(ev.clientX - gameContainer.clientWidth / 2) / gameContainer.clientWidth) * 2;
  const y = (-(ev.clientY - gameContainer.clientHeight / 2) / gameContainer.clientHeight) * 2;
  gameContainer.style.backgroundPosition = `${x}% ${y}%`;
});

window.addEventListener("beforeinstallprompt", (e: BeforeInstallPromptEvent) => {
  e.preventDefault();

  const deferredPrompt = e;
  installButton.style.display = "block";

  installButton.addEventListener("click", () => {
    installButton.style.display = "none";
    deferredPrompt.prompt();

    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
    });
  });
});

Assets.loadAssets([
  { name: "bummSound", type: "Audio", src: "assets/sounds/bumm.mp3" },
  { name: "shotSound", type: "Audio", src: "assets/sounds/pew.mp3" },
  { name: "music", type: "Audio", src: "assets/sounds/music1.mp3", loaderSettings: { loop: true, volume: 0.3 } },
  { name: "dieSound", type: "Audio", src: "assets/sounds/die.mp3" },
  { name: "ghci", type: "Image", src: "assets/ghci.png" },
  { name: "spaceShip", type: "Image", src: "assets/space_ship.png" },
  { name: "washingMachine", type: "Image", src: "assets/washing_machine.png" },
  { name: "dini", type: "Image", src: "assets/faces/dini.png" },
  { name: "thomas", type: "Image", src: "assets/faces/thomas.png" },
  { name: "zsofi", type: "Image", src: "assets/faces/zsofi.png" },
  { name: "explosionAtlas", type: "Json", src: "assets/explosion/atlas.json" },
  { name: "explosion", type: "Image", src: "assets/explosion/texture.png" },
]).then(() => {
  const game = new Game(canvas);
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    game.stick = new Stick(stick);
    shotButton.addEventListener("touchstart", () => game.shoot());
    ui.style.display = "block";
    // some code..
  } else {
    canvas.addEventListener("click", () => game.shoot());
    gameContainer.addEventListener("mousemove", ev => game.handleMouseMove(ev));

    window.addEventListener("keyup", ev => {
      if (ev.code === "Space") {
        game.shoot();
      }
    });
  }

  game.onDeath = () => {
    logoContainer.classList.remove("hidden");
    canvas.classList.add("hidden");
    ui.classList.add("hidden");
  };

  playButton.addEventListener("click", () => {
    logoContainer.classList.add("hidden");
    canvas.classList.remove("hidden");
    ui.classList.remove("hidden");
    gameContainer.requestFullscreen().then(() => {
      screen.orientation.lock("landscape");
    });
    game.play();
    game.onResize();
  });

  window.addEventListener("resize", () => game.onResize());
});
