export type AssetLoaderTypes = "Image" | "Audio" | "Json" | string;
export type AssetLoader = (src: string, settings?: unknown) => Promise<unknown>;

export interface AudioLoaderSettings {
  loop?: boolean;
  volume?: number;
}

export function loadAudio(src: string, settings?: AudioLoaderSettings): Promise<HTMLAudioElement> {
  return new Promise<HTMLAudioElement>((resolve, reject) => {
    const audio = new Audio(src);
    audio.onloadedmetadata = () => {
      if (settings !== undefined) {
        if (settings.loop !== undefined) audio.loop = settings.loop;
        if (settings.volume !== undefined) audio.volume = settings.volume;
      }
      resolve(audio);
    };
    audio.onerror = reject;
  });
}

export function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.src = src;
    image.onerror = reject;
  });
}

export async function loadJson<T>(src: string): Promise<T> {
  const x = await fetch(src);
  return await (<Promise<T>>x.json());
}

export interface AssetDefinition {
  src: string;
  name: string;
  type: AssetLoaderTypes;
  loaderSettings?: unknown;
}

class AssetManager {
  private _assets: Map<string, unknown>;
  private _loaders: Map<AssetLoaderTypes, AssetLoader>;

  constructor() {
    this._assets = new Map();
    this._loaders = new Map();

    this.addLoader("Image", loadImage);
    this.addLoader("Audio", loadAudio);
    this.addLoader("Json", loadJson);
  }

  public addLoader(loaderType: string, loader: AssetLoader) {
    this._loaders.set(loaderType, loader);
  }

  public getAsset<T>(name: string): T {
    return <T>this._assets.get(name);
  }

  public async loadAsset(assetDef: AssetDefinition) {
    const loader = this._loaders.get(assetDef.type);

    if (loader === undefined) {
      console.error(`Asset loader not found! Type:{${assetDef.type}}`);
      return;
    }

    const asset = await loader(assetDef.src, assetDef.loaderSettings);
    this._assets.set(assetDef.name, asset);
  }

  public loadAssets(assets: AssetDefinition[]) {
    return Promise.all(assets.map(x => this.loadAsset(x)));
  }
}

export const Assets = new AssetManager();
export const image = (name: string): HTMLImageElement => Assets.getAsset(name);
export const audio = (name: string): HTMLAudioElement => Assets.getAsset(name);
export const json = <T>(name: string): T => Assets.getAsset(name);
