import { Entity } from "./entity";
import { image } from "../assets";
import { Vector2 } from "../utils";
import { Head } from "../effects/head";

export class SpaceShip extends Entity {
  private _direction: Vector2;
  private _targetDirection: Vector2;
  private _head: Head;
  coolDown: number;
  health: number;
  rotationSpeed: number;

  constructor() {
    super();
    this.coolDown = 500;
    this._direction = new Vector2(0, 1);
    this._targetDirection = new Vector2(0, 1);
    this.rotationSpeed = Math.PI / 1000;
    this.health = 100;
    this._head = new Head(image("thomas"));
  }

  public set direction(direction: Vector2) {
    this._direction = direction.normalize();
  }
  public get direction(): Vector2 {
    return this._direction;
  }

  public get targetDirection(): Vector2 {
    return this._targetDirection;
  }

  public set targetDirection(direction: Vector2) {
    this._targetDirection = direction.normalize();
  }

  public get angle(): number {
    return this._direction.angle;
  }

  public set angle(value: number) {
    this.direction = new Vector2(Math.cos(value), Math.sin(value));
  }

  public update(deltaTime: number): void {
    const rotation = this.rotationSpeed * deltaTime;
    const scalar = this._direction.scalarProduct(this._targetDirection.rotate90());
    const angleDif = Math.abs(this.angle - this._targetDirection.angle);

    if (rotation < angleDif) {
      this.angle -= Math.sign(scalar) * rotation;
    } else {
      this.direction = this.targetDirection;
    }

    this._head.update(deltaTime);
  }

  public render(ctx: CanvasRenderingContext2D): void {
    const texture = image("spaceShip");
    ctx.save();
    const scale = 128 / Math.max(texture.width, texture.height);
    ctx.scale(scale, scale);
    ctx.rotate(this.angle + Math.PI / 2);
    this._head.draw(ctx, 0, 1.5, 8.5, 8.5);
    ctx.translate(-texture.width / 2, -texture.height * 0.4270833);
    ctx.drawImage(texture, 0, 0);
    ctx.restore();
  }
}
