import { Vector2 } from "./utils";

export class Stick {
  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;
  private _point: Vector2;
  private _scale: number;
  private _drawCallback: () => void;

  constructor(canvas: HTMLCanvasElement) {
    this._canvas = canvas;
    this._ctx = canvas.getContext("2d");
    //this._ctx.imageSmoothingEnabled = false;
    this._point = Vector2.NULL;
    this._scale = 1;
    this._drawCallback = this._draw.bind(this);

    this._canvas.addEventListener("touchstart", (ev: TouchEvent) => {
      const firstTouch = ev.touches[0];
      this._setPoint(firstTouch.clientX - this._canvas.offsetLeft, firstTouch.clientY - this._canvas.offsetTop);
    });

    this._canvas.addEventListener("touchmove", ev => {
      const firstTouch = ev.touches[0];
      this._setPoint(firstTouch.clientX - this._canvas.offsetLeft, firstTouch.clientY - this._canvas.offsetTop);
    });

    this._canvas.addEventListener("touchend", () => {
      this._setZero();
    });

    window.addEventListener("resize", () => {
      this._resize();
      setTimeout(() => this._resize(), 2000);
    });
    this._resize();
    requestAnimationFrame(this._drawCallback);
  }

  public get direction(): Vector2 {
    if (this._point.length > 0) {
      return this._point.normalize();
    }
    return Vector2.NULL;
  }

  private _resize() {
    const scale = Math.max(1, window.devicePixelRatio);
    this._scale = scale;
    this._canvas.width = Math.floor(this._canvas.clientWidth * scale);
    this._canvas.height = Math.floor(this._canvas.clientHeight * scale);
  }

  private _setPoint(clientX: number, clientY: number) {
    const w = this._canvas.clientWidth;
    const h = this._canvas.clientHeight;
    this._point = new Vector2(clientX - w / 2, clientY - h / 2).scale(this._scale);
  }

  private _setZero() {
    this._point = Vector2.NULL;
  }

  private _draw() {
    const ctx = this._ctx;
    const w = this._canvas.width / 2;
    const h = this._canvas.height / 2;
    const radius = Math.min(w, h);
    const mainRadius = radius * 0.75;

    let point = Vector2.NULL;
    if (this._point.length < mainRadius) {
      point = this._point;
    } else {
      point = this._point.normalize().scale(mainRadius);
    }

    ctx.save();
    ctx.clearRect(0, 0, w * 2, h * 2);

    ctx.translate(w, h);
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2 * window.devicePixelRatio;
    ctx.beginPath();
    ctx.arc(0, 0, mainRadius, 0, Math.PI * 2);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(point.x, point.y, radius * 0.25, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.restore();
    requestAnimationFrame(this._drawCallback);
  }
}
