import { image, json } from "../assets";
import { TexturePackerAtlas, TexturePackerFrame } from "../utils";

export class Explosion {
  private _deltaTime: number;
  private _frameTime: number;
  private _frameId: number;
  private _frame: TexturePackerFrame;
  private _atlasDefinition: TexturePackerAtlas;

  constructor() {
    this._deltaTime = 0;
    this._frameTime = 1000 / 60;
    this._frameId = 0;
    this._frame = undefined;
    this._atlasDefinition = json("explosionAtlas");
  }

  public get h(): number {
    return this._frame.frame.h;
  }

  public get w(): number {
    return this._frame.frame.w;
  }

  public get ready(): boolean {
    return this._frame !== undefined;
  }

  public update(deltaTime: number): void {
    this._deltaTime += deltaTime;
    this._frameId = Math.floor(this._deltaTime / this._frameTime);
    if (this._frameId < this._atlasDefinition.frames.length) {
      this._frame = this._atlasDefinition.frames[this._frameId];
    } else {
      this._frame = this._atlasDefinition.frames[this._atlasDefinition.frames.length - 1];
    }
  }

  public reset(): void {
    this._deltaTime = 0;
  }

  public isEnded(): boolean {
    return this._frameId >= this._atlasDefinition.frames.length;
  }

  public draw(ctx: CanvasRenderingContext2D, x: number, y: number, height: number, width: number): void {
    if (this._frame) {
      height *= 5;
      width *= 5;
      const frame = this._frame.frame;
      ctx.drawImage(
        image("explosion"),
        frame.x,
        frame.y,
        frame.w,
        frame.h,
        x - width / 2,
        y - height / 2,
        width,
        height
      );
    }
  }
}
