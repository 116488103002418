export abstract class GameBase {
  private _lastRequestFrame: number;
  private _startTime: number | undefined;
  private _canvas: HTMLCanvasElement;
  private _ctx: CanvasRenderingContext2D;
  private _paused: boolean;

  constructor(canvas: HTMLCanvasElement) {
    this._lastRequestFrame = -1;
    this._startTime = undefined;
    this._canvas = canvas;
    this._ctx = canvas.getContext("2d");
    this._paused = false;
    this._sizeCanvas();
  }

  public get canvas(): HTMLCanvasElement {
    return this._canvas;
  }

  public get ctx(): CanvasRenderingContext2D {
    return this._ctx;
  }

  protected abstract loop(elapsedTime: number): void;

  public pause(): void {
    if (this._lastRequestFrame > 0) {
      cancelAnimationFrame(this._lastRequestFrame);
      this._lastRequestFrame = -1;
      this._startTime = undefined;
      this._paused = true;
    }
  }

  public play(): void {
    if (this._lastRequestFrame == -1) {
      this._paused = false;
      this._lastRequestFrame = requestAnimationFrame(this._innerLoop.bind(this));
    }
  }

  private _clearCanvas() {
    this.ctx.save();
    this._ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();
  }

  private _innerLoop(timestamp: DOMHighResTimeStamp = 0): void {
    if (this._startTime === undefined) this._startTime = timestamp;
    const elapsed = timestamp - this._startTime;
    this._startTime = timestamp;
    this._clearCanvas();

    this.loop(elapsed);
    if (!this._paused) {
      this._lastRequestFrame = requestAnimationFrame(this._innerLoop.bind(this));
    }
  }

  private _sizeCanvas(): void {
    this._ctx.setTransform(1, 0, 0, 1, 0, 0);
    this._ctx.translate(this.canvas.width / 2, this.canvas.height / 2);
    const scale = Math.min(this.canvas.clientWidth, this.canvas.clientHeight) / 512;
    this._ctx.scale(scale, scale);
    this._ctx.imageSmoothingEnabled = false;
  }

  public onResize(): void {
    const scale = Math.max(1, window.devicePixelRatio * 0.5);

    this.canvas.width = Math.floor(this.canvas.clientWidth * scale);
    this.canvas.height = Math.floor(this.canvas.clientHeight * scale);
    this._sizeCanvas();
    this._ctx.scale(scale, scale);
  }
}
