export class Vector2 {
  public static readonly NULL = new Vector2();

  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  readonly x: number;

  readonly y: number;

  public get length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  public get angle(): number {
    return Math.atan2(this.y, this.x);
  }

  public normalize(): Vector2 {
    const length = this.length;
    return new Vector2(this.x / length, this.y / length);
  }

  public add(other: Vector2): Vector2 {
    return new Vector2(this.x + other.x, this.y + other.y);
  }

  public subtract(other: Vector2): Vector2 {
    return new Vector2(this.x - other.x, this.y - other.y);
  }

  public scale(n: number): Vector2 {
    return new Vector2(this.x * n, this.y * n);
  }

  public distance(other: Vector2): number {
    return this.subtract(other).length;
  }

  public scalarProduct(other: Vector2): number {
    return this.x * other.x + this.y * other.y;
  }

  public rotate90(clockwise = false): Vector2 {
    if (clockwise) {
      return new Vector2(this.y, -this.x);
    } else {
      return new Vector2(-this.y, this.x);
    }
  }
}

export interface TexturePackerSize {
  readonly w: number;
  readonly h: number;
}

export interface TexturePackerPosition {
  readonly x: number;
  readonly y: number;
}

export interface TexturePackerMetadata {
  readonly app: string;
  readonly version: string;
  readonly image: string;
  readonly format: string;
  readonly size: TexturePackerSize;
  readonly scale: number;
  readonly smartupdate: string;
}

export interface TexturePackerFrame {
  readonly filename: string;
  readonly frame: TexturePackerSize & TexturePackerPosition;
  readonly rotated: boolean;
  readonly trimmed: boolean;
  readonly spriteSourceSize: TexturePackerSize & TexturePackerPosition;
  readonly sourceSize: TexturePackerSize;
}

export interface TexturePackerAtlas {
  readonly frames: TexturePackerFrame[];
  readonly meta: TexturePackerMetadata;
}
